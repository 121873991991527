import React, { useState, useEffect } from 'react';
import '../styles/ContactSection.css';
import axios from 'axios';
import { Demo } from '../App';
import { useThemeColors } from '../ThemeContext';

interface ContactSectionProps {
  phoneNumber: string;
  onPhoneNumberChange: (newPhoneNumber: string) => void;
  demo: Demo | null;
  emailFrom: string;
  emailTo: string | string[];
}

interface ContactFormData {
  email: string;
  preferredContact: 'email' | 'phone';
  phone: string;
}

const ContactSection: React.FC<ContactSectionProps> = ({ phoneNumber: parentPhoneNumber, onPhoneNumberChange: onParentPhoneNumberChange, demo, emailFrom, emailTo }) => {
  const [formData, setFormData] = useState<ContactFormData>({
    email: '',
    preferredContact: 'email',
    phone: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isNextClicked, setIsNextClicked] = useState(false);
  const theme = useThemeColors();

  useEffect(() => {
    document.documentElement.style.setProperty('--contact-button-color', theme.contactButton);
  }, [theme.contactButton]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone: string) => {
    if (phone === '') return true;
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    return phoneRegex.test(phone);
  };

  const isEmailValid = validateEmail(formData.email);
  const isPhoneValid = validatePhoneNumber(formData.phone);
  const showEmailError = isNextClicked && emailFocused && !isEmailValid;
  const showPhoneError = isNextClicked && phoneFocused && !isPhoneValid;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setFormData({ ...formData, [name]: value });
    } else if (name === 'phone') {
      // onPhoneNumberChange(value);
      setFormData({ ...formData, [name]: value });
    }
    if (isNextClicked) {
      setEmailFocused(true);
      setPhoneFocused(true);
    }
  };

  const handleToggleChange = (preferredContact: 'email' | 'phone') => {
    setFormData({
      ...formData,
      preferredContact,
    });
  };

  const submitContact = async (email: string, phone: string, preferredContact: 'email' | 'phone') => {
    setIsSubmitting(true);
    setSubmitError(null);

    const emailContent = `
      New Contact Submission:
      
      Email: ${email}
      Phone: ${phone}
      Preferred Contact Method: ${preferredContact}
      
      ${demo ? `
      First Name: ${demo.first_name}
      Last Name: ${demo.last_name}
      Role: ${demo.role || "N/A"}
      Company Name: ${demo.company_name}
      Industry: ${demo.industry}
      Creation Date: ${demo.creation_date}
      ` : ''}
    `;

    try {
      const response = await axios.post('https://zsrbhkwknfhpqrvkzipj.supabase.co/functions/v1/resend-proxy', {
        from: emailFrom,
        to: emailTo,
        subject: 'Website: New Contact Form Submission' + (demo ? ' (' + demo.first_name + ' ' + demo.last_name + ')' : ''),
        text: emailContent,
      });

      if (response.status === 200) {
        setShowModal(true);
      } else {
        throw new Error('Failed to send email');
      }
    } catch (error) {
      console.error('Error submitting contact:', error);
      setSubmitError('An error occurred while submitting your information. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = () => {
    setIsNextClicked(true);
    if (isEmailValid && isPhoneValid) {
      submitContact(formData.email, formData.phone, formData.preferredContact);
    }
  };

  const isDisabled = () => {
    return isNextClicked && (!isEmailValid || !isPhoneValid || isSubmitting);
  }

  return (
    <section className="contact-section" id="contact">
      <div className="background-square square-1" style={{ background: theme.contactBackground }}></div>
      <div className="background-square square-2" style={{ background: theme.contactBackground }}></div>
      <div className="background-square square-3" style={{ background: theme.contactBackground }}></div>
      <div className="background-square square-4" style={{ background: theme.contactBackground }}></div>
      <div className="rectangle" style={{ background: theme.contactBackground }}></div>
      <div className="contact-card">
        <h2 style={{ color: theme.contactText }}>Let's be Buds</h2>
        <h2 style={{ color: theme.contactText, fontSize: '20px' }}>NEVER CHOOSE BETWEEN YOUR IN-STORE OR PHONE CUSTOMERS AGAIN!</h2>
        <p style={{ textAlign: 'left', color: theme.contactText }}>Leaf Call AI streamlines your web and phone services, enabling your budtenders to focus fully on delivering exceptional in-store care to patients and customers.</p>
        <div className="input-group">
          <input
            type="email"
            name="email"
            placeholder="What's Your Email?"
            value={formData.email}
            onChange={handleInputChange}
            onFocus={() => setEmailFocused(true)}
            className={showEmailError ? 'error' : ''}
          />
          {showEmailError && <div className="error-message">Please enter a valid email address.</div>}
        </div>
        <div className="input-group">
          <input
            type="tel"
            name="phone"
            placeholder="What's Your Phone Number?"
            value={formData.phone}
            onChange={handleInputChange}
            onFocus={() => setPhoneFocused(true)}
            className={showPhoneError ? 'error' : ''}
          />
          {showPhoneError && <div className="error-message">Please enter a valid phone number.</div>}
        </div>
        <div className="preferred-contact">
          <span>Preferred</span>
          <div className="pill-buttons">
            <button
              className={`pill-button ${formData.preferredContact === 'email' ? 'active' : ''}`}
              onClick={() => handleToggleChange('email')}
              style={{ backgroundColor: formData.preferredContact === 'email' ? theme.contactButton : '' }}
            >
              Email
            </button>
            <button
              className={`pill-button ${formData.preferredContact === 'phone' ? 'active' : ''}`}
              onClick={() => handleToggleChange('phone')}
              style={{ backgroundColor: formData.preferredContact === 'phone' ? theme.contactButton : '' }}
            >
              Phone
            </button>
          </div>
        </div>
        <button className='button' onClick={handleSubmit} disabled={isDisabled()} style={{ background: theme.contactButton }}>
          {isSubmitting ? 'Submitting...' : 'Next'}
        </button>
        {submitError && <div className="error-message">{submitError}</div>}
      </div>
      <div className={`modal-overlay ${showModal ? 'visible' : ''}`}>
        <div className="modal" style={{ display: 'block' }}>
          <h3 style={{ textAlign: 'left' }}>Thanks for reaching out!</h3>
          <p style={{ textAlign: 'left' }}>A member of our team will be in touch within two business days.</p>
          <button onClick={() => setShowModal(false)}>Close</button>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;