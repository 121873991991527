import React from 'react';
import VerticalPad from './VerticalPad';
import { useThemeColors } from '../ThemeContext';
import './SectionFeatures.css';

interface MessageBubbleProps {
  isUser: boolean;
  children: React.ReactNode;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ isUser, children }) => {
  const bubbleClass = isUser ? 'message-bubble-user' : 'message-bubble-ai';
  const tailClass = isUser ? 'message-tail-user' : 'message-tail-ai';

  return (
    <div className={`message-bubble ${bubbleClass}`}>
      {children}
      <div className={`message-tail ${tailClass}`} />
    </div>
  );
};

const SectionFeatures: React.FC = () => {
  const theme = useThemeColors();
  return (
    <section id="features" className="py-5" style={{ backgroundColor: theme.featureSectionBackground }}>
      <VerticalPad height={30} />
      <div className="container">
        <h2 className="text-center mb-4 text-white" style={{ fontSize: '48px' }}>Custom AI-Powered<br />Web & Phone Dispensary Agent</h2>
        <VerticalPad height={40} />

        <div className="message-container">
          <MessageBubble isUser={false}>
            Budtenders torn between serving in-store customers and handling incoming phone inquires?
          </MessageBubble>
          <MessageBubble isUser={true}>
            Leaf Call AI lets them focus exclusively on the customers in front of them.
          </MessageBubble>
          <MessageBubble isUser={false}>
            Concerned about losing current or potential customers to long hold times?
          </MessageBubble>
          <MessageBubble isUser={true}>
            Leaf Call AI is Always Available, Always Accurate, and Always delivers an awesome customer experience.
          </MessageBubble>
        </div>

        <VerticalPad height={80} />

        <div className="row">
          <div className="col-md-6">
            <div style={{
              backgroundColor: 'white',
              borderRadius: '15px',
              padding: '30px',
              position: 'relative',
              overflow: 'hidden',
              backgroundImage: `
                linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%),
                linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1)),
                url("/cash-register-scene.jpg")
              `,
              backgroundSize: '100% 60%, 100% 100%, auto 60%',
              backgroundPosition: 'center bottom, center center, right calc(100% + 110px)',
              backgroundRepeat: 'no-repeat',
            }}>
              <h2 className="mb-4" style={{ fontSize: '48px', color: '#333', borderBottom: `2px solid ${theme.featureText}`, paddingBottom: '10px', position: 'relative', zIndex: 1 }}>A versatile solution for the cannabis industry.</h2>
              {/* <p className="mb-3" style={{ fontSize: '18px', color: '#333', position: 'relative', zIndex: 1 }}>From personal assistants to receptionists. Streamline communications across:</p> */}
              <div style={{ fontSize: '18px', paddingLeft: '40px', lineHeight: '2', marginBottom: '30px', color: '#333', position: 'relative', zIndex: 1 }}>
                {['Fully Customizable Web and Phone Agent', 'Real-Time Menu and Specials Updates', 'Answers Multiple Calls Simultaneously', 'Personalized Dispensary Branding', 'Data Analytics Reporting', 'Encrypted Data Storage', '24-7 Customer Support'].map((item, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <span style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '24px', height: '24px', border: '2px solid #333', borderRadius: '4px', marginRight: '10px', fontSize: '18px', color: theme.featureText }}>✓</span>
                    <span style={{ fontWeight: '600', color: theme.featureText }}>{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <VerticalPad height={60} />
          </div>
          <div className="col-md-6">
            {[
              {
                number: "01",
                title: "Always Available",
                description: "60% of customers will hang up and try another dispensary after just one-minute on hold.Leaf Call AI's agent handles an unlimited number of incoming calls simultaneously, so you'll never lose a customer to long hold times again.",
                icon: (
                  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                )
              },
              {
                number: "02",
                title: "Always Accurate",
                description: "Our customizable web and phone agent sync's seamlessly with your dispensary's menu to provide real-time product availability, store specials, test results, terpene profiles, and so much more...",
                icon: (
                  <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                )
              },
              {
                number: "03",
                title: "Always Awesome",
                description: "With immediate and reliable web and phone service, our agent always provides an awesome customer experience ensuring your customers will always come back.",
                icon: (
                  <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                )
              }
            ].map((item, index) => (
              <div key={index} className="mb-4">
                <div className="rounded overflow-hidden">
                  <div className="d-flex">
                    <div className="text-white font-weight-bold d-flex align-items-center justify-content-center" style={{ width: '50px', padding: '12px', background: theme.featureCardHeaderNumberBackground }}>
                      {item.number}
                    </div>
                    <div className="text-white flex-grow-1 d-flex align-items-center" style={{ padding: '12px', background: theme.featureCardHeaderTitleBackground }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={theme.featureCardHeaderNumberBackground} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2" style={{ marginLeft: '3px', marginRight: '13px' }}>
                        {item.icon}
                      </svg>
                      <h3 className="mb-0" style={{ fontSize: '24px' }}>{item.title}</h3>
                    </div>
                  </div>
                  <div className="bg-white" style={{ padding: '20px' }}>
                    <p style={{ fontSize: '18px', marginBottom: '0', textAlign: 'left', color: theme.featureText }}>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFeatures;
